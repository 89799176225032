import { Link } from "react-router-dom";
function Footer(){
    return(
        <>
            <footer className="footer-main">
                <div className="cta-sec">
                    <div className="container">
                        <div className="cta">
                            <p>Just want to talk to a real person? We're here to help!</p>
                            <Link to="tel:9873192025">Call: +91-9873192025</Link>
                        </div>
                    </div>
                </div>
                <div className="footer-nav-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-3">
                                <h3 className="fn-h">About Shiptrack</h3>
                                <ul className="fn-link">
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/pricing">Pricing</Link></li>
                                    
                                    <li><Link to="/services">Services</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
									<li><Link to="/legal-notice">Legal notice</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
									<li><Link to="/term-condition">Term and Condition</Link></li>
                                </ul>
                            </div>
                            <div className="col-3">
                                <h3 className="fn-h">Our Services</h3>
                                <ul className="fn-link">
                                    <li><Link to="/services/export-import-logistics">Export-Import Logistics</Link></li>
                                    <li><Link to="/services/local-transportation">Local Transportation</Link></li>
                                    <li><Link to="/services/air-freight-import-export">Air freight Import - Export</Link></li>
									<li><Link to="/services/ocean-freight-import-export">Ocean Freight Import - Export</Link></li>
									<li><Link to="/services/freight-forwarding">Freight Forwarding</Link></li>
									<li><Link to="/services/customs-clearance">Customs Clearance</Link></li>
									<li><Link to="/services/warehousing-distribution">Warehousing & Distribution</Link></li>
                                    
                                </ul>
                            </div>
                            <div className="col-3">
                                <h3 className="fn-h">Contact us</h3>
                                <div className="fn-address">
									<p>
									 APN ShipTrack International EXIM LLP. <br />

										First Floor, Flat No. 3, Back Side, Village-Mahipalpur, <br />

										Kh No. 943/4, Near Mahipalpur Police Station, <br />

										New Delhi, Delhi-110037, BHARAT
								  </p>
								</div> 
                                                            
                            </div>
                            <div className="col-3">
                                <h3 className="fn-h">Follow us</h3>
                                <div className="sm-link">
                                    <Link to="#"><i className="fa fa-facebook"></i></Link>
                                    <Link to="#"><i className="fa fa-twitter"></i></Link>
                                    <Link to="#"><i className="fa fa-linkedin"></i></Link>
                                    <Link to="#"><i className="fa fa-instagram"></i></Link>
                                    <Link to="#"><i className="fa fa-youtube-play"></i></Link>
                                </div>
								
								 <div className="fn-qc">
								 <br />
                                    <p>Phone</p>
                                    <Link to="tel:9873192025">+91-9873192025</Link>
                                </div>
                                <div className="fn-qc">
                                    <p>Email Address</p>
                                    <Link to="mailto:contact@shiptrack.co.in">contact@shiptrack.co.in</Link>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    Copyright &copy; 2024 <strong>Shiptrack</strong>. All Rights Reserved
                </div>
            </footer>
        </>
    );
}

export default Footer;