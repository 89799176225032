import InnerPageHeading from "./InnerPageHeading";

function LegalNotice(){
    return(
        <>
            <InnerPageHeading title="Legal Notice"/>
            <section className="gfe-sec">
                <div className="container">
                    <p className="intro-lead">Legal Notice</p>
				    <p className="intro-txt">
					This Legal Notice governs your use of APN Shiptrack International Exim LLP's website and services related to Export-Import Logistics, Air Freight, Ocean Freight, Freight Forwarding, Customs Clearance, and Warehousing & Distribution. By accessing our website and using our services, you agree to comply with the terms outlined below.					
					</p>
					
					
					<p className="intro-lead">Intellectual Property</p>
				    <p className="intro-txt">
					All content, trademarks, and logos on this website are the property of APN Shiptrack International Exim LLP. Unauthorized use or reproduction of any materials is strictly prohibited.					
					</p>
					
					
					<p className="intro-lead">Disclaimer of Liability</p>
				    <p className="intro-txt">
					While we strive for accuracy in our service descriptions and pricing, APN Shiptrack International Exim LLP does not warrant that all information is complete or error-free. We are not liable for any direct, indirect, or consequential damages arising from your reliance on the information provided on our website.				
					</p>
					
					
					<p className="intro-lead">Service Availability</p>
				    <p className="intro-txt">
					Our services may be subject to availability and are provided on an "as-is" basis. We reserve the right to modify or discontinue services at any time without prior notice.					
					</p>
					
					
					<p className="intro-lead">Governing Law</p>
				    <p className="intro-txt">
					These terms are governed by the laws of india. Any disputes arising from this notice or your use of our services shall be resolved in the courts of india.				
					</p>
					
					<p className="intro-lead">Contact Information</p>
				    <p className="intro-txt">
					For any questions regarding this Legal Notice, please contact us at pramod@shiptrack.co.in.				
					</p>
					
                </div>
           </section>
        </>
    );
}

export default LegalNotice;