import { Link } from "react-router-dom";

function SidebarBlog(){
    return(
        <>
            <div className="sidebar-item">
                <form>
                    <div className="blog-search">
                        <input type="text" className="bs-input" placeholder="Find from blogs"/>
                        <button type="submit" className="btn-bs"><i className="fa fa-search"></i></button>
                    </div>
                </form>
            </div>
            <div className="sidebar-item">
                <h3>Categories</h3>
                <ul className="category-list">
                    <li><Link to="">Export-Import Logistics <span>(10)</span></Link></li>
                    <li><Link to="">Local Transportation <span>(21)</span></Link></li>
                    <li><Link to="">Air freight Import - Export <span>(15)</span></Link></li>
                    <li><Link to="">Ocean Freight Import - Export <span>(16)</span></Link></li>
                    <li><Link to="">Freight Forwarding <span>(18)</span></Link></li>
                    <li><Link to="">Customs Clearance <span>(05)</span></Link></li>
                </ul>
            </div>
            
        </>
    );
}

export default SidebarBlog;