import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import InnerPageHeading from "./InnerPageHeading";
import HomeWhyChooseUse from "./HomeWhyChooseUs";
import MissionAndVission from "./MissionAndVission";

function Thanks(){
    return(
        <>
			<Helmet>
				<title>Thanks APN Shiptrack International Exim LLP</title>
				<meta name="description" content="Fantastic Service" />
				<meta name="keywords" content="Fantastic Service" />
			  </Helmet>
           <InnerPageHeading title="About Us"/>
           <section className="home-intro">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <h1>Thanks</h1>
                           
							
							<p className="intro-txt">
							Thanks for contacting us.We will contact you soon.
							</p>
                          
                        </div>
                        <div className="col-6">
                            <img src="images/about_img_1.jpg" alt="" height={382} width={555}/>
                        </div>
                    </div>
					
					
                </div>
           </section>
           <HomeWhyChooseUse/>
           <MissionAndVission/>
        </>
    );
}

export default Thanks;