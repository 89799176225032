function HomeWhyChooseUse(){
    return(
        <>
             <section className="why-chooseus-sec">
                <div className="container">
                    <h2>What sets APN Shiptrack Apart?</h2>                
                    <div className="row">
					
						<div className="col-4">
                            <div className="why-choose-us">
                                <div className="icon">
                                    <i className="fa fa-user-o"></i>
                                </div>
                                <h3>Global Expertise</h3>
                                <p>Years of expertise guiding businesses through complex global logistics and trade. </p>
                            </div>
                        </div>
						
                        <div className="col-4">
                            <div className="why-choose-us">
                                <div className="icon">
                                    <i className="fa fa-star-o"></i>
                                </div>
                                <h3>Timely Delivery</h3>
                                <p>Timely deliveries ensured through efficient processes and a reliable global network. </p>
                            </div>
                        </div>
                       
                        <div className="col-4">
                            <div className="why-choose-us">
                                <div className="icon">
                                    <i className="fa fa-cog"></i>
                                </div>
                                <h3>24/7 Support</h3>
                                <p>24/7 support ensures smooth operations with real-time assistance, anytime. </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
           </section>
        </>
    );
}

export default HomeWhyChooseUse;