import { Link } from "react-router-dom";
function HomeIntro(){
    return(
        <>
            <section className="home-intro">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <h1>Welcome to APN Shiptrack International Exim LLP</h1>
                            <p className="intro-lead">At APN Shiptrack International Exim LLP, we empower businesses to thrive in the global marketplace by offering cutting-edge export-import logistics and supply chain management solutions. </p>
                            <p className="intro-txt">Our expertise ensures smooth cross-border operations, whether you're experiencing the complexities of international regulations, managing shipments, or streamlining your supply chain. We are committed to delivering efficient, reliable, and customized services that drive your business growth.<br /> With our global network, advanced technology, and personalized support, APN Shiptrack is your trusted partner for success in the ever-evolving world of international trade.</p>
                            <Link to="/contact">Contact us</Link>
                        </div>
                        <div className="col-6">
                            <img src="images/about_img_1.jpg" alt="" height={382} width={555}/>
                        </div>
						
                    </div>
                </div>
           </section>
        </>
    )
}

export default HomeIntro;