import InnerPageHeading from "./InnerPageHeading";

function TermCondition(){
    return(
        <>
            <InnerPageHeading title="Term and Condition"/>
            <section className="gfe-sec">
                <div className="container">
                    <p className="intro-lead">Terms and Conditions</p>
				    <p className="intro-txt">
					Welcome to APN Shiptrack International Exim LLP, By using our Export-Import Logistics, Air Freight, Ocean Freight, Freight Forwarding, Customs Clearance, and Warehousing & Distribution services, you agree to comply with and be bound by the following terms and conditions. 
					</p>
					
					<p className="intro-lead">Service Agreement</p>
				    <p className="intro-txt">
					Scope of Services: We provide logistics solutions as outlined in our service descriptions. Specific details will be provided in individual service agreements. <br />

Customer Responsibilities: Customers must provide accurate and complete information regarding shipments, including documentation for customs clearance. <br />

Payment Terms: All fees for services must be paid as outlined in the service agreement. Late payments may incur additional charges. <br />

 
					</p>
					
					
					<p className="intro-lead">Liability</p>
				    <p className="intro-txt">
					Limitations: While we take utmost care in handling your goods, [Your Company Name] is not liable for indirect, incidental, or consequential damages arising from delays or loss of goods, unless due to gross negligence. <br />

Insurance: We recommend obtaining insurance for valuable shipments. Liability for loss or damage will be limited to the extent of the chosen insurance coverage.



 
					</p>
					
					
					<p className="intro-lead">Compliance</p>
				    <p className="intro-txt">
					Customers must comply with all applicable laws and regulations regarding the shipping of goods. We reserve the right to refuse service if compliance cannot be ensured.

       				</p>
					
					
					<p className="intro-lead">Modifications</p>
				    <p className="intro-txt">
					We reserve the right to update these Terms and Conditions at any time. Continued use of our services after such changes constitutes your acceptance of the new terms.
						</p>
						
						
					<p className="intro-lead">Contact Information</p>
				    <p className="intro-txt">
					For any questions regarding these Terms and Conditions, please contact us at pramod@shiptrack.co.in.
						</p>
					
					
                </div>
           </section>
        </>
    );
}

export default TermCondition;