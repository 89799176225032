import InnerPageHeading from "./InnerPageHeading";

function PrivacyPolicy(){
    return(
        <>
            <InnerPageHeading title="Privacy Policy"/>
            <section className="gfe-sec">
                <div className="container">
				  <p className="intro-lead">Privacy Policy</p>
				  <p className="intro-txt">
					At APN Shiptrack International Exim LLP, we streamline global trade with reliable export-import logistics solutions. From freight management to customs clearance, our expertise ensures smooth, efficient transportation across borders. 
					</p>
					
					
				<p className="intro-lead">Information We Collect</p>
				  <p className="intro-txt">
					We may collect personal information, including but not limited to:<br /><br />
					
					Contact details (name, email, phone number) <br />
					Company information (business name, address)<br />
					Shipment details (package information, tracking numbers)<br />
					</p>
					
					
					
					<p className="intro-lead">How We Use Your Information</p>
				    <p className="intro-txt">
					We use your information for the following purposes:<br /><br />
					
					To provide and manage our logistics services <br />
					To communicate with you regarding your shipments<br />
					To comply with legal and regulatory requirements<br />
					To improve our services based on customer feedback<br />
					</p>
					
					
					<p className="intro-lead">Data Security</p>
				    <p className="intro-txt">
					We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, or misuse.


					</p>
					
					<p className="intro-lead">Sharing Your Information</p>
				    <p className="intro-txt">
					We do not sell or rent your personal information to third parties. We may share your information with trusted partners only to fulfill your logistics needs, and all parties are bound by confidentiality obligations.
					</p>
					
					
					<p className="intro-lead">Your Rights</p>
				    <p className="intro-txt">
					You have the right to access, update, or request deletion of your personal information. To exercise these rights, please contact us at pramod@shiptrack.co.in.
					</p>
					
					
					<p className="intro-lead">Changes to This Policy</p>
				    <p className="intro-txt">
					We may update this Privacy Policy periodically. We will notify you of any changes by posting the new policy on our website.
					</p>
					
					
					<p className="intro-lead">Contact Us</p>
				    <p className="intro-txt">
					If you have any questions about this Privacy Policy, please contact us at pramod@shiptrack.co.in. 
					</p>
					
			
                </div>
           </section>
        </>
    );
}

export default PrivacyPolicy;