import { Link } from "react-router-dom";
function HomeTestimonials(){
    return(
        <>
            <section className="ht-sec">
                <div className="container">
                    <h2>What Our Customers Are Saying</h2>
                    <div className="row">
                        <div className="col-4">
                            <div className="ht-item">
                                <p className="ht-msg">APN Shiptrack International Exim LLP made our international shipping seamless and stress-free. Their expertise in customs and timely deliveries has been a game-changer for our business</p>
                                <p className="ht-by">Lewis K. Gamino</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="ht-item">
                                <p className="ht-msg">Thanks to APN Shiptrack International Exim LLP, our goods move across borders efficiently every time. Their reliable service has been essential to our global operations.</p>
                                <p className="ht-by">Jason M. Harden</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="ht-item">
                                <p className="ht-msg">We trust APN Shiptrack  with all our logistics needs. Their reliable service and commitment to customer satisfaction have made them an invaluable partner in our supply chain.</p>
                                <p className="ht-by">Vicky R. McTaggart</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <Link to="/testimonial" className="link-btn">View all Testimonails</Link>
                    </div>
                </div>
           </section>
        </>
    );

}

export default HomeTestimonials;