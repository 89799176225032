import { Link } from "react-router-dom";
function HomeServices(){
    return(
        <>
           <section className="hs-sec">
                <div className="container">
                    <h2>our service</h2>
                    <div className="row">
                        <div className="col-4">
                            <Link to="/services/export-import-logistics" className="service-item">
                                <div className="service-img">
                                    <img src="service/export_import_logistics.jpg" alt="Export-Import Logistics" height={203} width={360}/>
                                </div>
                                <div className="service-title">Export-Import Logistics</div>
                            </Link>
                        </div>
                        <div className="col-4">
                            <Link to="/services/local-transportation" className="service-item">
                                <div className="service-img">
                                    <img src="service/local_transportation.jpg" alt="Local Transportation" height={203} width={360}/>
                                </div>
                                <div className="service-title">Local Transportation</div>
                            </Link>
                        </div>
                        <div className="col-4">
                            <Link to="/services/air-freight-import-export" className="service-item">
                                <div className="service-img">
                                    <img src="service/air_freight_import_export.jpg" alt="Air freight Import - Export" height={203} width={360}/>
                                </div>
                                <div className="service-title">Air freight Import - Export</div>
                            </Link>
                        </div>
						
						
						
						<div className="col-4">
                            <Link to="/services/ocean-freight-import-export" className="service-item">
                                <div className="service-img">
                                    <img src="service/ocean_freight_import_export.jpg" alt="Ocean Freight Import - Export" height={203} width={360}/>
                                </div>
                                <div className="service-title">Ocean Freight Import - Export</div>
                            </Link>
                        </div>
						
						<div className="col-4">
                            <Link to="/services/freight-forwarding" className="service-item">
                                <div className="service-img">
                                    <img src="service/freight_forwarding.jpg" alt="Freight Forwarding" height={203} width={360}/>
                                </div>
                                <div className="service-title">Freight Forwarding</div>
                            </Link>
                        </div>
						
						<div className="col-4">
                            <Link to="/services/customs-clearance" className="service-item">
                                <div className="service-img">
                                    <img src="service/customs_clearance.jpg" alt="Customs Clearance" height={203} width={360}/>
                                </div>
                                <div className="service-title">Customs Clearance</div>
                            </Link>
                        </div>
						
                        
                    </div>
                    <div className="text-center">
                        <Link to="/getestimate" className="link-btn">Get a Quote</Link>
                    </div>
                </div>
           </section>
        </>
    );
}

export default HomeServices