import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InnerPageHeading from './InnerPageHeading';
import CountryForm from './CountryForm';

function ServiceListSingle(){
	
	const { id } = useParams();  // use 'id' instead of 'country'
	const [content, setContent] = useState(<div>Page Coming Soon...</div>);
	const [backgroundImage, setBackgroundImage] = useState("/images/page_header.jpg");

	useEffect(() => {
    if (!id) {
      setContent(<div>Page Coming Soon...</div>);
      setBackgroundImage("/images/page_header.jpg");
      return;
    }

    const fetchData = async () => {
      try {
        let imageUrl = "/images/page_header.jpg"; // Default image URL
        let contentComponent = <div>Page Coming Soon...</div>;

        switch (id.toLowerCase()) {
          case 'export-import-logistics':
            contentComponent = <ExportContent />;
            imageUrl = "/global/Australia-hero-img.jpeg";
            break;
          case 'local-transportation':
            contentComponent = <LocalContent />;
            imageUrl = "/global/china-hero-img.jpeg";
            break;
          case 'air-freight-import-export':
            contentComponent = <AirContent />;
            imageUrl = "/global/india-hero-img.jpeg";
            break;
          case 'ocean-freight-import-export':
            contentComponent = <OceanContent />;
            imageUrl = "/service/ocean_freight_import_export.jpg";
            break;
          case 'freight-forwarding':
            contentComponent = <FreightContent />;
            imageUrl = "/global/Malaysia-hero-img.jpeg";
            break;
          case 'customs-clearance':
            contentComponent = <CustomsContent />;
            imageUrl = "/global/WeFreight-Mexico.jpg";
            break;
          case 'warehousing-distribution':
            contentComponent = <WarehousingContent />;
            imageUrl = "/global/Saudi-Arabia-hero-img.jpeg";
            break;
          
          default:
            contentComponent = <div>Page Coming Soon...</div>;
        }

        setContent(contentComponent);
        setBackgroundImage(imageUrl);
      } catch (error) {
        console.error("Error fetching content:", error);
        setContent(<div>Error loading content</div>);
      }
    };

    fetchData();
  }, [id]);

  // Define country-specific content components
  const ExportContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Export-Import Logistics Services</h1>
          <p className="intro-lead"></p>
          <p className="intro-txt">
            At APN Shiptrack International Exim LLP, we streamline global trade with reliable export-import logistics solutions. From freight management to customs clearance, our expertise ensures smooth, efficient transportation across borders. 
            </p>
			 <p className="intro-lead">Key Services:</p>
			<p>
			Freight Management: Air, ocean, and land freight, with door-to-door delivery for FCL & LCL shipments.
			</p>
			<p>
			Customs Clearance: Expert handling of import/export documentation, compliance, and duty calculations.
			</p>
			<p>
			Warehousing & Distribution: Secure storage, inventory management, and fast distribution.
			</p>
			<p>
			Cargo Insurance: Comprehensive protection for your shipments.
			</p>
			<p>
			Let us handle your logistics while you focus on growing your business globally!
          </p>
		   <p className="intro-txt"> </p>
        </div>
		
        <div className="col-12"><CountryForm /></div>
      </div>
    </div>
  );
  
  
  // Define country-specific content components
  const LocalContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Local Transportation Services</h1>
          <p className="intro-lead"></p>
          <p className="intro-txt">
            At APN Shiptrack International Exim LLP, we provide fast, reliable, and cost-effective local transportation solutions. Whether you need to move goods within the city or across the region, our fleet and experienced drivers ensure timely deliveries with the utmost care. 
            </p>
			 <p className="intro-lead">Key Services:</p>
			<p>
			Same-Day & Next-Day Delivery: Prompt service for urgent shipments.

			</p>
			<p>
			Fleet Options: Tailored transport solutions with trucks, vans, and specialized vehicles.
			</p>
			<p>
			Last-Mile Delivery: Efficient final-leg service to ensure goods reach their destination on time.
			</p>
			<p>
			Real-Time Tracking: Stay updated with live tracking and delivery status.
			</p>
			<p>
			Trust us to deliver your goods safely and on schedule!
          </p>
		   <p className="intro-txt"> </p>
        </div>
        <div className="col-12"><CountryForm /></div>
      </div>
    </div>
  );
  
   // Define country-specific content components
  const AirContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Air freight Import - Export Services</h1>
         <p className="intro-lead"></p>
          <p className="intro-txt">
            APN Shiptrack International Exim LLP offers fast, secure, and reliable air freight solutions for your import and export needs. Whether shipping urgent goods or high-value cargo, we ensure your shipments reach their destination quickly and safely. 
            </p>
			 <p className="intro-lead">Key Services:</p>
			<p>
			Global Reach: Extensive network of carriers for worldwide coverage.

			</p>
			<p>
			Customs Clearance: Smooth import/export clearance and regulatory compliance.
			</p>
			<p>
			Priority & Standard Shipping: Flexible options to meet your timelines and budget.
			</p>
			<p>
			Tracking & Security: Real-time tracking and top-tier cargo handling for peace of mind.
			</p>
			<p>
			Trust us to deliver your goods safely and on schedule!
          </p>
		   <p className="intro-txt"> </p>
        </div>
        <div className="col-12"><CountryForm /></div>
      </div>
    </div>
  );
  
   // Define country-specific content components
  const OceanContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Ocean Freight Import - Export Services</h1>
          <p className="intro-lead"></p>
          <p className="intro-txt">
            At APN Shiptrack International Exim LLP, we offer reliable and cost-efficient ocean freight solutions for global import and export needs. Whether you’re shipping full containers (FCL) or smaller loads (LCL), we ensure safe and timely deliveries across major sea routes.
            </p>
			 <p className="intro-lead">Key Services:</p>
			<p>
			FCL & LCL Shipping: Tailored solutions for large and small cargo.

			</p>
			<p>
			Global Network: Access to major ports worldwide for seamless shipping.
			</p>
			<p>
			Customs Clearance: Expert handling of documentation and compliance.
			</p>
			<p>
			Cargo Tracking: Stay updated with real-time shipment visibility.
			</p>
			<p>
			Trust us for secure and efficient ocean freight services to keep your goods moving globally!
          </p>
		   <p className="intro-txt"> </p>
        </div>
        <div className="col-12"><CountryForm /></div>
      </div>
    </div>
  );
  
  // Define country-specific content components
  const FreightContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Freight Forwarding Services</h1>
          <p className="intro-lead"></p>
          <p className="intro-txt">
            APN Shiptrack International Exim LLP offers comprehensive freight forwarding solutions to simplify your global shipments. We handle every aspect of your logistics, ensuring smooth transport of goods across air, ocean, and land.
            </p>
			 <p className="intro-lead">Key Services:</p>
			<p>
			Multi-Modal Transport: Seamless coordination across air, sea, and land freight.

			</p>
			<p>
			Customs Brokerage: Expertise in regulatory compliance and documentation.
			</p>
			<p>
			Global Network: Strong partnerships with carriers for worldwide reach.
			</p>
			<p>
			End-to-End Management: From pickup to delivery, we manage the entire logistics process.
			</p>
			<p>
			Streamline your supply chain with our expert freight forwarding services!
          </p>
		   <p className="intro-txt"> </p>
        </div>
        <div className="col-12"><CountryForm /></div>
      </div>
    </div>
  );
  /////////////////////////////////////////////
  
  // Define country-specific content components
  const CustomsContent = () => (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Customs Clearance Services</h1>
         <p className="intro-lead"></p>
          <p className="intro-txt">
            APN Shiptrack International Exim LLP provides expert customs clearance services to ensure your imports and exports move smoothly through international borders. We handle all necessary documentation, compliance, and duties, minimizing delays and avoiding penalties.
            </p>
			 <p className="intro-lead">Key Services:</p>
			<p>
			Documentation Management: Accurate preparation and submission of customs paperwork.

			</p>
			<p>
			Regulatory Compliance: Expertise in local and international trade regulations.
			</p>
			<p>
			Duty & Tax Calculations: Efficient handling of tariffs and duties.
			</p>
			<p>
			Customs Consultancy: Guidance on trade agreements and product classifications.
			</p>
			<p>
			Let us take care of customs, so your shipments cross borders seamlessly!
          </p>
		   <p className="intro-txt"> </p>
        </div>
        <div className="col-12"><CountryForm /></div>
      </div>
    </div>
  );
  
  // Define country-specific content components
  const WarehousingContent = () => (
    <div className="container">
      <div className="row">
       <div className="col-12">
          <h1>Warehousing & Distribution Services</h1>
          <p className="intro-txt">
            APN Shiptrack International Exim LLP offers secure, efficient warehousing and distribution solutions to support your supply chain. Our state-of-the-art facilities and optimized distribution networks ensure timely, accurate deliveries to your customers.
            </p>
			 <p className="intro-lead">Key Services:</p>
			<p>
			Storage Solutions: Safe, scalable warehousing for short or long-term needs.

			</p>
			<p>
			Inventory Management: Real-time tracking and stock control.
			</p>
			<p>
			Order Fulfillment: Fast and accurate picking, packing, and shipping.
			</p>
			<p>
			Strategic Distribution: Optimized delivery routes for faster customer reach.
			</p>
			<p>
			Rely on us for seamless warehousing and distribution that keeps your business running smoothly!
          </p>
		   <p className="intro-txt"> </p>
        </div>
        <div className="col-12"><CountryForm /></div>
      </div>
    </div>
  );

  // Define similar components for other countries

  const countryTitle = id ? id.charAt(0).toUpperCase() + id.slice(1) : "Unknown";

  return (
    <>
      <InnerPageHeading title={`Global Serives Estimate - ${countryTitle}`} backgroundImage={backgroundImage} />
      <section className="gfe-sec">
        <div className="container">
          <div className="content">
            {content}
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceListSingle;