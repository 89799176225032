import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import InnerPageHeading from "./InnerPageHeading";
import HomeWhyChooseUse from "./HomeWhyChooseUs";
import MissionAndVission from "./MissionAndVission";

function AboutUs(){
    return(
        <>
			<Helmet>
				<title>About APN Shiptrack International Exim LLP</title>
				<meta name="description" content="Fantastic Service" />
				<meta name="keywords" content="Fantastic Service" />
			  </Helmet>
           <InnerPageHeading title="About Us"/>
           <section className="home-intro">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <h1>About APN Shiptrack International Exim LLP</h1>
                            <p className="intro-lead">At APN Shiptrack International Exim LLP, we are dedicated to transforming global trade through our expert export-import logistics and custom clearance services.</p>
                            <p className="intro-txt">Founded with a vision to streamline international trade operations, we have grown into a trusted partner for businesses around the world, delivering reliable, efficient, and customized solutions.</p>
							
							<p className="intro-txt">
							 With our deep industry expertise, we provide end-to-end logistics support that simplifies the movement of goods across borders. Our services encompass everything from freight forwarding and customs clearance to warehousing and distribution. With cutting-edge technology and a global network of partners, we ensure that your supply chain remains agile, responsive, and fully compliant with international regulations.

							</p>
                          
                        </div>
                        <div className="col-6">
                            <img src="images/about_img_1.jpg" alt="" height={382} width={555}/>
                        </div>
                    </div>
					
					<div className="row">
						<div className="col-6">
						  <div className="mb30">
							<h2 className="mb20">Global Expertise, Local Presence</h2>
							<p className="intro-txt">With a strong global footprint and deep knowledge of regional markets, APN Shiptrack offers a unique blend of local expertise and international reach. Whether you’re a small business looking to expand overseas or a large enterprise managing complex logistics, we provide the strategic support to help you navigate the ever-changing world of global commerce.</p>			
							<Link to="/contact" className="btn-default-link">
							  Let’s Simplify Your Global Trade
							</Link>
						  </div>
						</div>
						<div className="col-6">
						  <img src="/images/about1.jpeg" alt="" className="img-fluid" />
						</div>
					  </div>
					  
					  
					
                </div>
           </section>
           <HomeWhyChooseUse/>
           <MissionAndVission/>
        </>
    );
}

export default AboutUs;